@import "../scss/colors";

:root {
    --ck-color-focus-border: #{$green};
    --ck-color-base-active: #{$green};
    --ck-color-base-active-focus: #{$dark-green};
    --ck-color-focus-outer-shadow: #{$green-30};
    --ck-table-selected-cell-background: #{$green-30};
    --ck-color-table-focused-cell-background: #{$green-30};
}
