@import "variables";

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

p,
span {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

html,
body,
#atlas {
    margin: 0;
    padding: 0;
    height: 100%;
    background-color: #333;
    overscroll-behavior: contain;
}

.nospinner input[type="number"]::-webkit-inner-spin-button,
.nospinner input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
